<exam>
  <div class="uk-container uk-container-center" id="container">

    <div class="uk-grid">
      <!-- PC/tablet -->
      <div class="uk-hidden-small uk-width-medium-1-1 uk-width-large-1-1 uk-text-center uk-margin-large-top uk-margin-large-bottom">
        <img src="image/bathlier-logo.png" width="80" height="80" />
        <span id="title" class="uk-h2 uk-margin-left uk-text-middle">バスリエ検定</span>
      </div>
      <!-- mobile -->
      <div class="uk-width-small-1-1 uk-hidden-medium uk-hidden-large uk-text-center uk-margin-top uk-margin-bottom">
        <img src="image/bathlier-logo.png" width="40" height="40" />
        <span id="title" class="uk-h3 uk-margin-left uk-text-middle">バスリエ検定</span>
      </div>
    </div>

    <!-- IE以外 -->
    <div if={ !(obs.agent.indexOf('msie') >= 0 || obs.agent.indexOf('trident') >= 0) } class="uk-flex uk-flex-center uk-margin-top" style="color: #e6c747;">
      { percentage } %&nbsp;
      <div class="uk-progress uk-progress-warning uk-width-2-3">
        <div id="progress-bar" class="uk-progress-bar" style="width: { percentage }%;"></div>
      </div>
    </div>
    <!-- IE -->
    <div if={ (obs.agent.indexOf('msie') >= 0 || obs.agent.indexOf('trident') >= 0) } class="uk-flex uk-flex-center uk-margin-top" style="color: #e6c747;">
      <div class="uk-progress uk-progress-warning uk-width-2-3">
        <div id="progress-bar" class="uk-progress-bar" style="width: { percentage }%;"></div>
      </div>
    </div>

    <div>
      <!-- 事前情報入力 -->
      <div class="ui form" id="input-info">
        <div class="field">
          <lable name="name">お名前</label>
          <input type="text" id="name" name="name" placeholder="お名前"/>
        </div>
        <div class="field">
          <lable name="mail_address">メールアドレス</label>
          <input type="email" id="mail_address" name="mail_address" placeholder="メールアドレス"/>
        </div>
        <div class="field uk-margin-large-top uk-text-center">
          <button id="start-button" class="uk-button uk-button-large uk-button-success uk-width-2-3" onClick={ examStart }>試験を始める</button>
        </div>

        <div class="uk-margin-large-top uk-text-center uk-text-small">
          <span class="uk-text-bold uk-text-danger">※ 検定試験中の注意</span><br />
          検定試験中はブラウザバックやリロードを行うと試験は最初からとなりますのでご注意下さい<br /><br />
          <span class="uk-text-bold uk-text-danger">※ 個人情報について</span><br />
          当画面にて入力されるお客様の個人情報はバスリエ検定の合否通達以外に利用は致しません。<br />
          （個人情報保護責任者：バスリエ株式会社　代表 松永武）
        </div>
      </div>
      <!-- /事前情報入力 -->
      <!-- テスト問題 -->
      <div class="uk-hidden uk-animation-fade" id="exam" style="position: relative; z-index: 3;" if={ current_program }>
        <div class="uk-margin-top uk-hidden-small"></div>
        <p class="uk-margin-top" id="program-title"></p>
        <div class="uk-margin-top uk-hidden-small"></div>
        <div class="uk-form uk-margin-top">
          <div class="uk-form-row uk-margin-left" each={ current_program.answers }>
            <!-- radio -->
            <label if={ current_program.type == 1 }><input type="radio" name="answer" value={ no }>&nbsp; { text }</label>
            <!-- checkbox -->
            <label if={ current_program.type == 2 }><input type="checkbox" id="ans-check-{ no }" name="answer" value={ no }>&nbsp; { text }</label>
            <!-- select -->
            <label if={ current_program.type == 3 }><input type="checkbox" name="answer" value={ no }>&nbsp; { text }</label>
          </div>

          <div class="uk-margin-large-top uk-text-center">
            <button if={ !correct && !incorrect } id="button" class="uk-button uk-button-large uk-button-primary uk-width-2-3" onClick={ answerCheck }>回答</button>
            <button if={ incorrect } id="start-button" class="uk-button uk-button-large uk-button-primary uk-width-2-3" onClick={ againProgram }>もう一度挑戦する</button>
          </div>
        </div>

        <!-- 正解画像 -->
        <img if={ correct } src='image/correct.png' class='uk-animation-scale' style='position: absolute; right: 0; top: 0; bottom: 0; left: 0; margin: auto;'></img>
        <!-- 不正解画像 -->
        <img if={ incorrect } src='image/incorrect.png' class='uk-animation-scale' style='position: absolute; right: 0; top: 0; bottom: 100px; left: 0; margin: auto; '></img>

      </div>
      <!-- /テスト問題 -->
    </div>
  </div>

  <img src="image/foot-img.png" class="uk-margin-large-top" width="100%" id="footer" />

  <script>
    var self          = this
    self.exam_number  = 0
    self.percentage   = 0
    self.total_number = 0
    self.programs     = []
    self.id           = 0
    self.name         = ""
    self.mail_address = ""
    self.correct      = false
    self.incorrect    = false

    $.get(obs.domain + "/api/v1/bathlier/program").done(function(response) {
      self.total_number    = response.total_number
      self.programs        = response.program
      self.current_program = self.programs[0]
      self.update()
    }).fail(function(response) {
      UIkit.notify("データを取得できませんでした")
    })

    // 試験開始
    examStart(e) {
      $('#input-info').addClass('loading')

      self.name          = $('#name').val()
      self.mail_address  = $('#mail_address').val()

      if(self.name == null || self.name == "") {
        $('#input-info').removeClass('loading')
        return UIkit.notify("名前を入力して下さい", { status: 'warning' })
      }

      if(self.mail_address == null || self.mail_address == "") {
        $('#input-info').removeClass('loading')
        return UIkit.notify("メールアドレスを入力して下さい", { status: 'warning' })
      }

      if(self.mail_address.match(/.+@.+\..+/)==null) {
        $('#input-info').removeClass('loading')
        return UIkit.notify("メールアドレスを正しく入力して下さい", { status: 'warning' })
      }

      var enc_name = CryptoJS.AES.encrypt(self.name, obs.key)
      var enc_mail = CryptoJS.AES.encrypt(self.mail_address, obs.key)

      var send_params = {
        name:         enc_name.toString(),
        mail_address: enc_mail.toString(),
        s:            obs.key
      }

      $.post(obs.domain + '/api/v1/bathlier/register', send_params).done(function(response) {
        self.id           = response.user.id
        self.name         = response.user.name
        self.mail_address = response.user.mail_address
      }).fail(function(response) {
        console.log("failed")
      })

      $('#input-info').removeClass('loading')
      $("#input-info").addClass("uk-hidden");
      $("#exam").removeClass("uk-hidden");

      self.current_program = self.programs[self.exam_number]
      $("#program-title").html(self.current_program.program)
      self.update();
    }

    answerCheck(e) {
      var answer = $("input[name=answer]:checked").val();

      if(answer == null) {
        return UIkit.notify("回答を選択して下さい", { status: 'danger' })
      }

      // 正解の場合
      if(scoring()) {
        self.exam_number++
        self.percentage = Math.round((self.exam_number / self.programs.length) * 100);
        self.correct    = true

        // 進捗の送信
        var send_params = {
          id: self.id,
          no: self.exam_number,
        }

        $.post(obs.domain + '/api/v1/bathlier/progress', send_params).done(function(response) {
        }).fail(function(response) {
        })

        self.update();

        // 2秒後に次の問題へ
        setTimeout(nextProgram, 2000)

      } else {
        self.incorrect = true
        self.update();
      }
    }

    // 採点
    function scoring() {
      // radioボタンの採点
      if(self.current_program.type == 1) {
        var answer = $("input[name=answer]:checked").val();
        if(self.current_program.correct_no == answer) { return true } else { return false }
      }

      // checkboxの採点
      if(self.current_program.type == 2) {
        var corrects    = self.current_program.correct_no
        var correct_num = 0

        $.each(self.current_program.answers, function(index, ans) {
          var result  = false
          var checked = $("#ans-check-" + ans.no).prop('checked')

          if(checked && corrects[ans.no]) {
            result = true
          } else if(!checked && !corrects[ans.no]) {
            result = true
          }

          if(result) { correct_num++ }
        })

        if(correct_num == self.current_program.answers.length) { return true } else { return false }
      }

    }

    againProgram(e) {
      self.incorrect = false
      self.update
    }

    function nextProgram() {
      $("#exam").removeClass("uk-animation-fade");
      $('#exam').transition('fade')

      // 全問正解
      if(self.percentage == 100) {
        riot.mount('success', { id: self.id })
        self.unmount()
        return

      } else {
        setTimeout(function() {
          self.current_program = self.programs[self.exam_number]
          self.correct         = false
          $("#program-title").html(self.current_program.program)
          self.update()
          $('#exam').transition('fade')
        }, 1000)
      }
    }

  </script>
</exam>
