'use strict';

import riot  from 'riot';
import route from 'riot-route';
window.route = route

window.riot = riot

const $ = require("jquery");
window.$ = $
window.jQuery = $

var obs = riot.observable()
window.obs = obs

import CryptoJS from 'crypto-js';
window.CryptoJS = CryptoJS

require("../uikit/uikit.min.js")
require("../uikit/components/slideshow.min.js")
require("../uikit/components/notify.min.js")
require("../uikit/components/slideshow-fx.min.js")

require("../tag/introduction")
require("../tag/exam.tag")
require("../tag/success.tag")
