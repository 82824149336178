<introduction>
  <div id="fb-root"></div><script async defer crossorigin="anonymous" src="https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.3&appId=177322622963987&autoLogAppEvents=1"></script>

  <div class="uk-container uk-container-center" id="container">
    <!-- header -->
    <div class="uk-grid">
      <!-- PC/tablet -->
      <div class="uk-hidden-small uk-width-medium-1-1 uk-width-large-1-1 uk-text-center uk-margin-large-top uk-margin-large-bottom">
        <img src="image/bathlier-logo.png" width="80" height="80" />
        <span id="title" class="uk-h2 uk-margin-left uk-text-middle">バスリエ検定</span>
      </div>
      <!-- mobile -->
      <div class="uk-width-small-1-1 uk-hidden-medium uk-hidden-large uk-text-center uk-margin-top">
        <img src="image/bathlier-logo.png" width="40" height="40" />
        <span id="title" class="uk-h3 uk-margin-left uk-text-middle">バスリエ検定</span>
      </div>
    </div>

    <div class="uk-hidden-small uk-margin-top"></div>
    <div class="uk-text-center uk-margin-top">
      <div class="ui orange statistic">
        <div class="label">
          合格者
        </div>
        <p class="value" id="pass-num"></p>
      </div>
    </div>

    <p class="uk-text-center uk-margin-large-top">
      お風呂をもっと深く知ることで仕事や暮らしに役立てていただきたい！<br />
      毎日の気分、体調、目的、悩みに合わせた最高のバスタイムを実現しましょう。
    </p>

    <p class="uk-text-center uk-margin-top">
      <i class="write icon"></i> 全28問 &nbsp;
      <i class="wait icon"></i> 目安時間 10分
    </p>
    <div class="uk-hidden-small uk-margin-top"></div>
    <div class="uk-text-center">
      <a href="exam_text.pdf" download="exam_text.pdf" id="start-button" class="uk-margin-top uk-button uk-button-large uk-button-primary uk-width-1-3">ダウンロード</a>
    </div>
    <div class="uk-text-center uk-text-muted">
      <span>バスリエ資格テキスト(4.5MB)</span>
    </div>

    <div class="uk-hidden-small uk-margin-top"></div>
    <div class="uk-text-center uk-margin-top">
      <button id="start-button" class=" uk-button uk-button-large uk-button-primary uk-width-1-3" onclick={ startExam }>検定スタート</button>
    </div>

    <!-- SNS -->
    <div class="uk-grid uk-margin-top">
      <div class="uk-width-1-2 uk-text-right">
        <div class="fb-share-button" data-href="https://www.bath-kentei.com/" data-layout="button" data-mobile-iframe="true"><a class="fb-xfbml-parse-ignore" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.bath-kentei.com%2F&amp;src=sdkpreparse">シェア</a></div>
      </div>
      <div class="uk-width-1-2 uk-text-left">
        <a href="https://twitter.com/share" class="twitter-share-button" data-url="https://www.bath-kentei.com/" data-text="仕事や暮らしに役立つ！お風呂の資格「バスリエ検定」" data-lang="ja">ツイート</a> <script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?'http':'https';if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src=p+'://platform.twitter.com/widgets.js';fjs.parentNode.insertBefore(js,fjs);}}(document, 'script', 'twitter-wjs');</script>
      </div>
    </div>

    <p class="uk-text-center uk-margin-large-top">
      <span class="uk-text-muted">※バスリエ検定はバスリエ株式会社が運営する民間資格です。</span>
    </p>
  </div>

  <img src="image/foot-img.png" width="100%" id="footer" />


  <script>
    var self = this
    self.pass_num = 0

    $.get(obs.domain + "/api/v1/skey/").done(function(response) {
      obs.key = response.s
    }).fail(function(response) {
      UIkit.notify("通信ができません")
    })

    $.get(obs.domain + "/api/v1/bathlier/pass_num").done(function(response) {
      self.pass_num = response.pass_num
      // IEでriotのタグが表示されないのでjQueryで対応
      $('#pass-num').append(self.pass_num)
      self.update()
    }).fail(function(response) {
      UIkit.notify("通信ができません")
    })

    startExam(e) {
      //riot.mount('exam')
      riot.mount('success', { id: 1262 })
      self.unmount()
    }


  </script>

</introduction>
