<success>
  <div class="uk-container uk-container-center" id="container">

    <div class="uk-grid">
      <!-- PC/tablet -->
      <div class="uk-hidden-small uk-width-medium-1-1 uk-width-large-1-1 uk-text-center uk-margin-large-top uk-margin-large-bottom">
        <img src="image/bathlier-logo.png" width="80" height="80" />
        <span id="title" class="uk-h2 uk-margin-left uk-text-middle">バスリエ検定</span>
      </div>
      <!-- mobile -->
      <div class="uk-width-small-1-1 uk-hidden-medium uk-hidden-large uk-text-center uk-margin-top uk-margin-bottom">
        <img src="image/bathlier-logo.png" width="40" height="40" />
        <span id="title" class="uk-h3 uk-margin-left uk-text-middle">バスリエ検定</span>
      </div>
    </div>

    <div class="uk-margin-top uk-hidden-small"></div>
    <div id="congrats" class="uk-margin-top uk-animation-scale-down uk-hidden">
      <h2 class="ui red center aligned icon header" onclick={ testClick }>
        <i class="child icon"></i> 合格おめでとうございます!<br />
        あなたは<span id="pass-num"></span>人目の合格者です</span>
      </h2>
    </div>

    <div class="ui transition hidden uk-margin-large-top uk-margin-bottom" id="select" style="position: relative; z-index: 3;">
      <!-- IE以外 -->
      <div if={ !(obs.agent.indexOf('msie') >= 0 || obs.agent.indexOf('trident') >= 0) } class="uk-flex uk-flex-center uk-margin-top" style="color: #e6c747;">
        100 %&nbsp;
        <div class="uk-progress uk-progress-warning uk-width-2-3">
          <div id="progress-bar" class="uk-progress-bar" style="width: 100%;"></div>
        </div>
      </div>
      <!-- IE -->
      <div if={ (obs.agent.indexOf('msie') >= 0 || obs.agent.indexOf('trident') >= 0) } class="uk-flex uk-flex-center uk-margin-top" style="color: #e6c747;">
        <div class="uk-progress uk-progress-warning uk-width-2-3">
          <div id="progress-bar" class="uk-progress-bar" style="width: 100%;"></div>
        </div>
      </div>

      <div class="uk-container">
        <p class="uk-margin-top uk-margin-large-bottom" id="program-title">最後に質問です。<br />「＋BATHLIER（プラスバスリエ）」への会員登録はお済みですか？</p>
      </div>

      <div class="uk-grid uk-margin-top uk-margin-large-bottom">
        <div class="uk-width-1-2 uk-text-center uk-text-small">
          <p>+BATHLIERに登録済みの方</p>
          <button id="button" class="uk-button uk-button-large uk-button-primary uk-width-2-3" onclick={ openRegisted }>はい</button>
        </div>
        <div class="uk-width-1-2 uk-text-center uk-text-small">
          <p>+BATHLIERに未登録の方</p>
          <button id="button" class="uk-button uk-button-large uk-button-primary uk-width-2-3" onclick={ openNotRegisted }>いいえ</button>
        </div>
      </div>
    </div>


    <!-- 登録済みの方 -->
    <div class="ui transition hidden uk-margin-bottom" id="registed" style="position: relative; z-index: 3;">
      <div class="uk-margin-large-top">
        <p class="uk-margin-large-bottom">
          この度はバスリエ検定を受験いただき、誠にありがとうございます。<br /><br />
          バスリエ資格を通じて、お風呂に入ることの大切さ、素晴らしさを一緒に発信し、バスリエとして豊かな暮らしを実現しましょう。<br /><br />
          認定証書は2～3週間以内に「＋BATHLIER（プラスバスリエ）」に登録のご住所に発送予定です。<br />
          認定証書の到着まで暫くお待ちください。<br /><br />

          <span class="uk-text-danger">
            注：認定証書の受領をもって本登録完了及びサービスのご利用開始となりますのでご注意ください。
          </span>
        </p>
      </div>

      <div class="uk-text-center uk-margin-large-top">
        <button class="uk-button uk-button-large uk-button-success uk-width-2-3" style="background: #7dc6c4;" onclick={ registedMail }>+BATHLIER</button>
      </div>
      <div class="uk-text-center uk-margin-top uk-margin-large-bottom">
        <button class="uk-button uk-button-large uk-margin-top" onclick={ reverse }>戻る</button>
      </div>
    </div>
    <!-- 登録済みの方 -->

    <!-- 未登録の方 -->
    <div class="ui transition hidden uk-margin-bottom" id="not-registed" style="position: relative; z-index: 3;">
      <div class="uk-margin-large-top">
        <!-- PC用 -->
        <div class="uk-hidden-small">
          <p class="uk-clearfix uk-margin-large-top uk-margin-bottom">
            この度はバスリエ検定を受験いただき、誠にありがとうございます。<br />
            バスリエ資格を通じて、お風呂に入ることの大切さ、素晴らしさを一緒に発信し、<br />
            バスリエとして豊かな暮らしを実現しましょう。<br /><br />
          </p>
        </div>

        <!-- mobile用 -->
        <div class="uk-hidden-medium uk-hidden-large">
          <p>
            この度はバスリエ検定を受験いただき、誠にありがとうございます。<br /><br />
            バスリエ資格を通じて、お風呂に入ることの大切さ、素晴らしさを一緒に発信し、<br />
            バスリエとして豊かな暮らしを実現しましょう。<br /><br /><br /><br />
          </p>
        </div>
      </div>

      <div class="uk-margin-large-top">
        <!-- PC用 -->
        <div class="uk-hidden-small uk-margin-large-top uk-margin-large-bottom">
          <div class="uk-grid">
            <div class="uk-width-1-2 uk-text-center">
              <button id="start-button" class="uk-button uk-button-large uk-button-success uk-width-2-3" onclick={ wantSell }>事業者の方</button>
            </div>
            <div class="uk-width-1-2 uk-text-center">
              <button id="start-button" class="uk-button uk-button-large uk-button-success uk-width-2-3" onclick={ otherUser }>個人の方</button>
              <p>
                ※ご登録の際にIDが必要となります。<br />
                「bathlier」と入力の上、ご登録をお願い致します。
              </p>
            </div>
          </div>
        </div>

        <!-- mobile用 -->
        <div class="uk-hidden-medium uk-hidden-large uk-margin-large-top">
          <div id="mobile-button" class="fluid ui button" tabindex="0" onclick={ wantSell }>事業者の方</div>

          <div id="mobile-button" class="ui top attached button uk-margin-large-top" tabindex="1" onclick={ otherUser }>個人の方</div>
          <div class="ui attached segment" onclick={ otherUser }>
            <p>
              ※ご登録の際にIDが必要となります。<br />
              「bathlier」と入力の上、ご登録をお願い致します。
            </p>
          </div>
        </div>

      </div>
      <div class="uk-text-center uk-margin-top uk-margin-large-bottom">
        <button class="uk-button uk-button-large uk-margin-top" onclick={ reverse }>戻る</button>
      </div>
    </div>
    <!-- /未登録の方 -->
  </div>

  <img src="image/foot-img.png" width="100%" class="ui transition hidden" id="footer" />


  <script>
    var self = this
    self.pattern = 0

    $.get(obs.domain + "/api/v1/bathlier/pass_num").done(function(response) {
      self.pass_num = response.pass_num
      self.pass_num++
      $('#pass-num').append(self.pass_num)
      self.update()

      $('#select').transition('fade')
      $('#footer').transition('fade')
    }).fail(function(response) {
      UIkit.notify("通信ができません")
    })

    openRegisted(e) {
      self.pattern = 1
      // メールを送る
      sendMail(1)

      $('#select').transition('fade')
      $('#footer').transition('fade')
      $('#congrats').transition('fade')
      setTimeout(function() {
        $('#registed').transition('fade')
        $('#footer').transition('fade')
      }, 1500)
      // データを登録
    }

    openNotRegisted(e) {
      self.pattern = 2

      $('#select').transition('fade')
      $('#footer').transition('fade')
      $('#congrats').transition('fade')

      setTimeout(function() {
        $('#not-registed').transition('fade')
        $('#footer').transition('fade')
      }, 1500)
    }

    reverse(e) {
      if(self.pattern == 1) {
        $('#registed').transition('fade')
      } else {
        $('#not-registed').transition('fade')
      }
      $('#congrats').transition('fade')
      $('#select').transition('fade')
    }

    registedMail(e) {
      location.href = "https://www.bathlier.jp/";
    }

    wantSell(e) {
      // メールを送る
      sendMail(2)
      location.href = "https://www.bathlier.jp/page/business";
    }

    otherUser(e) {
      // メールを送る
      sendMail(3)
      window.open( "https://www.bathlier.jp/page/guest", "_blank" ) ;
    }

    function showSuccess() {
      $('#success').transition('fade')
      $('#footer').transition('fade')
    }


    function sendMail(mail_type) {
      var send_params = {
        id:        opts.id,
        mail_type: mail_type
      }
      $.post(obs.domain + '/api/v1/bathlier/send_mail', send_params).done(function(response) {
      })
    }

  </script>
</success>
